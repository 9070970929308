.app {
  overflow-y: scroll;
  /* height: 94vh; */
  scroll-behavior: smooth;
}
.app::-webkit-scrollbar {
  display: none;
}
.sidebar-content {
  padding:10px 10px !important;
}
.main-content {
  overflow-y: scroll;
  padding: 5px;
  height:100vh;
}
.main-content::-webkit-scrollbar {
  display: none;
}

.pcard {
  box-shadow: 0 0 20px var(--bs-color);
  margin: 1px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  padding-bottom: 5px;
  height: 300px;
  background: var(--bg-color__variant);
  transition: 0.3s all ease-in-out;
}
.pcard:hover {
  /* box-shadow:2px 1px 3px var(--text-color), 2px 1px 2px var(--text-color); */
}
.pcard-brand {
  position: absolute;
  top: 10px;
  left: 20px;
  border: 1px solid var(--text-color);
  background: var(--bg-color);
  padding: 1px 8px;
  color: var(--text-color);
  border-radius: 50px;
}
.pcard-img {
  height: 60% !important;
}
.pcard-img img {
  object-fit: scale-down;
  /* mix-blend-mode: multiply; */
}
.pcard-body {
  height: 40% !important;
  padding: 5px;
}
.pcard-name {
  font-weight: 600;
  color: var(--text-color);
}
.pcard-price {
  min-width: fit-content !important;
}
.pcard-price__old {
  color: var(--text-color);
  font-size: 0.6rem;
  text-decoration: line-through;
}
.pcard-price__new {
  color: var(--text-color);
  font-weight: 700;
}
.pcard-btn {
  font-size: 12px !important;
}

.ec-rating__icon {
}

@media screen and (max-width: 1000px) {
  .sidebar-content {
    /* width: 35%; */
  }
}

@media screen and (max-width: 765px) {
  /* product card */
  .ec-rating__icon,
  .ec-rating__text {
    font-size: 8px;
    line-height: 1px;
  }
  .pcard {
    height: 160px;
    width: 100%;
  }
  .pcard-brand {
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 10px;
  }
  .pcard-img {
    height: 45% !important;
  }
  .pcard-body {
    height: 50% !important;
    padding: 2px;
  }
  .pcard-name {
    font-size: 12px;
  }
  .pcard-price__old {
    font-size: 8px;
  }
  .pcard-price {
    min-width: fit-content !important;
  }
  .pcard-price__new {
    width: fit-content;
    font-size: 12px;
  }
  .pcard-btn {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 8px !important;
    margin: 0 !important;
    height: 20px !important;
  }
}


