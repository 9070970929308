@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 200;
  src: url(../fonts/Nunito-ExtraLightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 300;
  src: url(../fonts/Nunito-LightItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 400;
  src: url(../fonts/Nunito-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 600;
  src: url(../fonts/Nunito-SemiBoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 700;
  src: url(../fonts/Nunito-BoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 800;
  src: url(../fonts/Nunito-ExtraBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 900;
  src: url(../fonts/Nunito-BlackItalic.ttf) format("truetype");
}
/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 200;
  src: url(../fonts/Nunito-ExtraLight.ttf) format("truetype");
}

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/Nunito-Light.ttf) format("truetype");
}
/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Nunito-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/Nunito-SemiBold.ttf) format("truetype");
}
/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/Nunito-Bold.ttf) format("truetype");
}

/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  src: url(../fonts/Nunito-ExtraBold.ttf) format("truetype");
}

/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/Nunito-Black.ttf) format("truetype");
}
