body {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  font-family: "Nunito", sans-serif;
}

:root {
  --bg-color: #ffffff;
  --bg-color__variant: #cad5e2;
  --text-color: #4285f4;
  --text-color__variant: #4285f4;
  --link-color: #1967d2;
  --bs-color: rgb(66 133 244 / 20%);
  --input-color: #f1f3f4;
}

body.light {
  --bg-color: #ffffff;
  --bg-color__variant: #fff;
  --text-color: #4285f4;
  --text-color__variant: #4285f4;
  --link-color: #1967d2;
  --bs-color: rgb(66 133 244 / 15%);
  --input-color: #f1f3f4;
}
body.dark {
  --bg-color: #000;
  --bg-color2: #f1f3f43d;
  --bg-color__variant: #f1f3f43d;
  --text-color: #fff;
  --text-color__variant: #4285f4;
  --bs-color: rgb(255 255 255 /20%);
  --input-color: #f1f3f43d;
}

body {
  background-color: var(--bg-color);
  background-image: url(./assets/cart.svg);
  background-position: center;
  /* background-repeat: no-repeat; */
}

body.darker {
  background-image: url(./assets/cart2.svg) !important;
}

.cp {
  cursor: pointer;
}

.text-primary {
  color: var(--text-color) !important;
}
.text-secondary {
  color: var(--text-color__variant) !important;
}
.bg-primary {
  background-color: var(--bg-color) !important;
}
.bg-secondary {
  background-color: var(--bg-color__variant) !important;
}

.br-xl {
  border-radius: 15px;
}

.btn {
  border-radius: 10px !important;
  outline: none !important;
  border: none !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  box-shadow:inset 0 0 15px var(--bs-color);
  /* box-shadow: 0 1px 3px var(--text-color), 0 1px 2px var(--text-color); */
  transition: all 0.3s ease-in-out;
}

.btn-primary {
  background-color: var(--text-color);
  opacity: 0.8;
  /* box-shadow: 40px 40px 80px rgba(0, 0, 0, 0.5),-40px -40px 80px #c0d9ff inset !important; */
  color: var(--bg-color);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  color: var(--bg-color);
  background-color: var(--text-color) ;
  opacity: 1;
}
.btn-outline {
  color: var(--text-color);
  border: 1px solid var(--text-color);
}
.btn-outline:hover,
.btn-outline:focus {
  background-color: var(--text-color) !important;
  color: var(--bg-color) !important;
}

.btn-link {
  color: var(--text-color) !important;
  text-decoration: none;
  /* box-shadow: unset; */
}
.btn-link:hover,
.btn-link:focus {
  background-color: var(--text-color) !important;
  color: var(--bg-color) !important;
  opacity: 0.8;
}

.br-md-xl {
  border-radius: 15px;
}

@media screen and (max-width: 765px) {
  .br-md-xl {
    border-radius: 0;
  }
}

input {
  outline: none;
  border: 2px solid var(--text-color);
  border-radius: 8px;
  color: var(--text-color);
  font-weight: 700 !important;
}
