.info-banner {
  width: 100%;
  /* height: 20vh; */
  /* overflow-x: scroll; */
}
/*.info-banner::-webkit-scrollbar {
  display: none;
} */
.info-banner__item {
  height: 300px;
  /* margin: 10px 30px; */
}

.info-banner__img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.info-banner .slick-slider .slick-dots {
  bottom: -15px;
}
.info-banner .slick-slider .slick-arrow {
  /* left: 10px; */
  /* background-color: red; */
}
.info-banner .slick-slider .slick-prev {
  left: 20px;
  z-index: 1;
  /* righ */
}
.info-banner .slick-slider .slick-next {
  right: 10px;
}
@media screen and (max-width: 1000px) {
  .info-banner__item {
    height: 250px;
  }
}

@media screen and (max-width: 650px) {
  .info-banner__item {
    height: 150px;
  }
  .info-banner .slick-slider .slick-arrow,
  .info-banner .slick-slider .slick-dots {
    display: none !important;
  }
}
